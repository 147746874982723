import axiosIns from '@/libs/axios';
const state = {
  urunRoute: [
    {
      title: 'Ürün Bilgileri',
      name: 'urun-bilgisi',
      icon: 'BookOpenIcon',
      route: { name: 'urun-bilgisi', params: { k_no: null } },
      disable: false,
    },
    {
      title: 'Fotograflar',
      name: 'urun-fotograflar',
      icon: 'ImageIcon',
      route: { name: 'urun-fotograflar', params: { k_no: null } },
      disable: true,
    },
    {
      title: 'Tarih & Fiyat',
      name: 'tarih-fiyat',
      icon: 'DollarSignIcon',
      route: { name: 'tarih-fiyat', params: { k_no: null } },
      disable: true,
    },
    {
      title: 'Dahil & Haric Hizmetler',
      name: 'dahil-haric',
      icon: 'DollarSignIcon',
      route: { name: 'dahil-haric', params: { k_no: null } },
      disable: true,
    },
    {
      title: 'İptal Politikaları',
      name: 'iptal-politika',
      icon: 'DollarSignIcon',
      route: { name: 'iptal-politika', params: { k_no: null } },
      disable: true,
    },
    {
      title: 'Tag ( Etiketler )',
      name: 'tag',
      icon: 'DollarSignIcon',
      route: { name: 'tag', params: { k_no: null } },
      disable: true,
    },
    {
      title: 'Dil Seçenekleri',
      name: 'dil-secenekleri',
      icon: 'DollarSignIcon',
      route: { name: 'dil-secenekleri', params: { k_no: null } },
      disable: true,
    },
    {
      title: 'Güzergah Bilgisi',
      name: 'guzergah-bilgisi',
      icon: 'DollarSignIcon',
      route: { name: 'guzergah-bilgisi', params: { k_no: null } },
      disable: true,
    },
    {
      title: 'Diğer Bilgiler',
      name: 'diger-bilgiler',
      icon: 'DollarSignIcon',
      route: { name: 'diger-bilgiler', params: { k_no: null } },
      disable: true,
    },
  ],
  urunler: [],

  urun: {},
};
const getters = {
  getUrunRoute: (state) => state.urunRoute,
  getUrunler: (state) => state.urunler,
  getUrun: (state) => state.urun,
};
const mutations = {
  SET_URUN(state, payload) {
    state.urun = payload;
    let index = state.urunler.findIndex((x) => x._id == payload._id);
    state.urunler[index] = payload;
  },

  SET_URUNLER(state, payload) {
    state.urunler = payload;
  },
  SET_URUNLER_PUSH(state, payload) {
    state.urunler.push(payload);
  },
  SET_URUN_ROUTE(state, payload) {
    state.urunRoute.forEach((element, i) => {
      if (payload.k_no != null) {
        state.urunRoute[i].route.params.k_no = payload.k_no;
        state.urunRoute[i].disable = false;
      } else {
        state.urunRoute[i].route.params.k_no = payload.k_no;
        if (element.name != 'urun-bilgisi') state.urunRoute[i].disable = true;
      }
    });
  },
  SET_URUN_ROUTE_UPDATE(state, payload) {
    let index = state.urunRoute.findIndex((x) => x.name == payload.name);
    state.urunRoute[index].disable = payload.data;
    state.urunRoute[0].route.params.k_no = payload.k_no;
    state.urunRoute[index].route.params.k_no = payload.k_no;
  },
  SET_URUNLER_UPDATE(state, payload) {
    let index = state.urunler.findIndex((x) => x._id == payload._id);
    state.urunler[index] = payload;
  },
  SET_URUNLER_DELETE(state, k_no) {
    let index = state.urunler.findIndex((x) => x.k_no == k_no);
    state.urunler.splice(index, 1);
  },
};
const actions = {
  async urunListele({ commit }, data) {
    return await axiosIns.post('/urun/urun-listele', data).then((res) => {
      commit('SET_URUNLER', res.data.data);
      return res;
    });
  },
  async urunSiraListele({ commit }, data) {
    return await axiosIns.post('/urun/urun-sira-listele', data).then((res) => {
      // commit('SET_URUNLER', res.data.data);
      return res;
    });
  },
  async urunSiraGuncelle({ commit }, data) {
    return await axiosIns.post('/urun/urun-sira-guncelle', data).then((res) => {
      // commit('SET_URUNLER', res.data.data);
      return res;
    });
  },
  async urunBilgiEkle({ commit }, data) {
    return await axiosIns.post('/urun/urun-bilgi-ekle', data).then((res) => {
      commit('SET_URUNLER_PUSH', res.data.data);
      return res;
    });
  },
  async urunBilgiGuncelle({ commit }, data) {
    return await axiosIns.post('/urun/urun-bilgi-guncelle', data).then((res) => {
      commit('SET_URUNLER_UPDATE', res.data.data);
      return res;
    });
  },
  async urunDahilHaricHizmetGuncelle({ commit }, data) {
    return await axiosIns.post('/urun/urun-dahil-haric-hizmet-guncelle', data).then((res) => {
      if (res.data.success == true) {
        commit('SET_URUN', res.data.data);
      }
      return res;
    });
  },
  async urunDahilHaricHizmetSil({ commit }, data) {
    return await axiosIns.post('/urun/urun-dahil-haric-hizmet-sil', data).then((res) => {
      commit('SET_URUN', res.data.data);
      return res;
    });
  },
  async urunIptalPolitikaGuncelle({ commit }, data) {
    return await axiosIns.post('/urun/urun-iptal-politika-guncelle', data).then((res) => {
      if (res.data.success == true) {
        commit('SET_URUN', res.data.data);
      }
      return res;
    });
  },
  async urunIptalPolitikaSil({ commit }, data) {
    return await axiosIns.post('/urun/urun-iptal-politika-sil', data).then((res) => {
      commit('SET_URUN', res.data.data);
      return res;
    });
  },
  async urunSil({ commit }, k_no) {
    return await axiosIns.post('/urun/urun-sil', { k_no }).then((res) => {
      commit('SET_URUNLER_DELETE', k_no);
      return res;
    });
  },
  async urunResimSiraGuncelle({ commit }, data) {
    return await axiosIns.post('/urun/urun-resim-sira-guncelle', data).then((res) => {
      commit('SET_URUN', res.data.data);
      return res;
    });
  },
  async urunResimSil({ commit, state }, data) {
    const payload = {
      _id: state.urun._id,
      ...data,
    };
    return await axiosIns.post('/urun/urun-resim-sil', payload).then((res) => {
      commit('SET_URUN', res.data.data);
      return res;
    });
  },
  async urunResimKapak({ commit, state }, data) {
    const payload = {
      _id: state.urun._id,
      ...data,
    };
    return await axiosIns.post('/urun/urun-resim-kapak', payload).then((res) => {
      commit('SET_URUN', res.data.data);
      return res;
    });
  },
  async handlerUrunFindOne({}, k_no) {
    return await axiosIns.post('/urun/urun-find-one', { k_no }).then((res) => {
      return res;
    });
  },
};

export default {
  namespage: true,
  state,
  getters,
  mutations,
  actions,
};
